<template>
  <div class="rank-top">
    <div
      v-for="(item, index) in topRankList"
      :key="item.userId"
      class="top-item"
      :class="
        index === 0 ? 'rank-first' : index === 1 ? 'rank-second' : 'rank-third'
      "
    >
      <div class="profile">
        <el-avatar
          icon="el-icon-user-solid"
          :size="index === 0 ? 62 : 48"
          :src="item.avatar"
          class="avatar"
        ></el-avatar>
        <img
          :src="require(`@/assets/image/rank-img/rank${++index}.png`)"
          class="frame"
        />
      </div>

      <div class="username">{{ item.name }}</div>
      <div class="title">{{ item.title || '小小编程家' }}</div>
      <ul class="info">
        <li class="info-item">
          <img src="@/assets/image/rank-img/star-white.png" alt="" />
          <p>{{ item.collectNum }}</p>
        </li>
        <li class="info-item">
          <img src="@/assets/image/rank-img/support-white.png" alt="" />
          <p>{{ item.starNum }}</p>
        </li>
        <li class="info-item">
          <img src="@/assets/image/rank-img/view-white.png" alt="" />
          <p>{{ item.viewNum }}</p>
        </li>
        <li class="info-item">
          <img src="@/assets/image/rank-img/code-white.png" alt="" />
          <p>{{ item.codeLines || 0 }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    topRankList: Array,
  },
};
</script>

<style lang="scss" scoped>
.rank-top {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .top-item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: absolute;
    color: #fff;
  }
  .rank-first {
    width: 200px;
    left: 408px;
    top: 62px;
    .profile {
      width: 84px;
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .username {
      font-size: 14px;
      font-weight: bold;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .info {
      display: flex;
      width: 100%;
      flex-flow: row wrap;
      justify-content: space-evenly;
      &-item {
        min-width: 34%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        img {
          width: 16px;
          margin-right: 10px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }

  .rank-second,
  .rank-third {
    width: 100px;
    .profile {
      width: 62px;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .username {
      font-size: 12px;
      font-weight: bold;
    }
    .title {
      font-size: 12px;
      font-weight: bold;
    }
    .info {
      display: flex;
      width: 100%;
      flex-flow: row wrap;
      justify-content: space-evenly;
      &-item {
        min-width: 34%;
        display: flex;
        align-items: center;
        margin-top: 5px;
        img {
          width: 14px;
          margin-right: 5px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .rank-second {
    left: 306px;
    top: 108px;
  }
  .rank-third {
    right: 288px;
    top: 112px;
  }
}
</style>